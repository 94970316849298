.main {
  position: relative;
  min-height: 100vh;
}

.container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 10rem;
  padding: 5rem;
  background-color: #fefefe;
  border-radius: 1rem;

  &__headerTitle {
    display: flex;
    justify-content: center;
    font-size: 2rem !important;
    font-weight: bold;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;

    p {
      text-align: justify;
      line-height: 1.75;
      margin: 0.3rem;
    }

    &__socialMedia {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.4rem;
        text-decoration: none !important;
        color: #212121 !important;
        font-size: 1rem !important;
        cursor: pointer;

        svg {
          font-size: 2rem !important;
        }

        &:hover {
          color: #fabd72 !important;
          transition: 0.4s;
        }
      }
    }

    &__contactus {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: flex-start;

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.4rem;
        color: #212121 !important;
        font-size: 1rem !important;

        svg {
          font-size: 2rem !important;
        }

        a {
          text-decoration: none !important;
          color: #212121 !important;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    & {
      margin: 10rem 5rem !important;
      padding: 2.5rem !important;
    }
  }

  @media screen and (max-width: 700px) {
    & {
      margin: 10rem 1rem !important;
    }
  }

  @media screen and (max-width: 500px) {
    & {
      padding: 1.5rem !important;
    }
  }
}

.divider {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
