@import url("./Fonts.css");

* {
  box-sizing: border-box !important;
}

*,
::after,
::before {
  font-family: LemonMilkLight !important;
  font-size: 0.9rem !important;
}

body {
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f6f5f3 !important;
}

.font_bold {
  font-family: LemonMilkBold !important;
}

.font_light {
  font-family: LemonMilkLight !important;
}

.font_regular {
  font-family: LemonMilkRegular !important;
}
