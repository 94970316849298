.main {
  position: relative;
  min-height: 100vh;
}

.videoPlayerBox {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  & video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  &__controller {
    width: 100%;
    height: 10rem;
    background: linear-gradient(
      to top,
      rgba(17, 17, 17, 0.6),
      rgba(17, 17, 17, 0.4) 40%,
      rgba(17, 17, 17, 0.2),
      rgba(17, 17, 17, 0.1),
      transparent
    );
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
  }
}

.iconPlayPause {
  width: fit-content;
  height: auto;
  color: #fefefe;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

.icon {
  font-size: 1.7rem !important;
}

.gridImage {
  padding: 3rem 40px 3rem 16px !important;
  margin: 0px !important;
  width: 100% !important;
  overflow: hidden !important;

  &__item {
    display: flex !important;
    flex-direction: column !important;
    &__container {
      overflow: hidden !important;
      width: 100%;
      height: 100%;
      &__img {
        width: 100%;
        transition: transform 0.3s ease;
        cursor: pointer;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    &__caption {
      font-size: 16px !important;
      font-weight: bold !important;
      margin-top: 20px !important;
      cursor: pointer !important;
      transition: 0.4s !important;
      text-decoration: none;
      color: #212121;
      &:hover {
        color: #fabd72 !important;
        transition: 0.4s !important;
      }
    }
  }
}

.emptyBody {
  height: 20vh;
}
