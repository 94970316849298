.main {
  position: relative;
  min-height: 100vh;
}

.videoPlayerBox {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  & video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  &__controller {
    width: 100%;
    height: 10rem;
    background: linear-gradient(
      to top,
      rgba(17, 17, 17, 0.6),
      rgba(17, 17, 17, 0.4) 40%,
      rgba(17, 17, 17, 0.2),
      rgba(17, 17, 17, 0.1),
      transparent
    );
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
  }
}

.iconPlayPause {
  width: fit-content;
  height: auto;
  color: #fefefe;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

.icon {
  font-size: 1.7rem !important;
}

.scrollComponent {
  position: absolute;
  bottom: 2%;
  right: 50%;
  transform: translate(50%, -2%);
  color: #fefefe;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    font-size: 10px !important;
    margin-bottom: 1rem;
  }

  &__animation {
    background-color: hsla(0, 0%, 100%, 0.14);
    display: block;
    height: 1.675rem;
    position: relative;
    width: 2px;
    z-index: 3;

    &::after {
      content: "";
      background: #fff;
      display: block;
      height: 100%;
      width: 2px;
      position: absolute;
      top: 0;
      animation: line 0.6s ease-in-out 0.6s infinite alternate;
      transform-origin: center top;
    }
  }
}

.container {
  max-width: 100vw;
  overflow: hidden;
}

.mainImage {
  width: 100%;
  height: fit-content;
  position: relative;
  &__img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  &__contentBox {
    width: 100vw;
    height: 20rem;
    background: linear-gradient(
      to top,
      rgba(17, 17, 17, 0.6),
      rgba(17, 17, 17, 0.4) 40%,
      rgba(17, 17, 17, 0.2),
      rgba(17, 17, 17, 0.1),
      transparent
    );
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    &__content {
      width: 100%;
      height: fit-content;
    }
  }
}

.content {
  padding: 2rem 4rem;
}

.emptyBody {
  height: 5vh;
}

@media screen and (max-width: 1100px) {
  .content {
    padding: 2rem 2rem;

    img{
      width: 100% !important;
      height: auto !important;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  }
}

@keyframes line {
  0% {
    transform: scaleY(0.25);
  }
  100% {
    transform: scaleY(0.75) translateY(25%);
  }
}
