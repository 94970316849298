.navbar {
  width: 100%;
  height: 90px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  transition: 0.4s;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;

  &__logo {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 3rem !important;
    cursor: pointer;
    display: flex;
    flex: 1;
    color: #fefefe;
    transition: 0.4s;
  }

  &__menuBox {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 1.5rem;
    transition: 0.4s;

    &__left {
      justify-content: flex-start;
    }

    &__right {
      justify-content: flex-end;
    }

    &__menuItem {
      text-decoration: none !important;
      color: #fefefe;
      transition: 0.4s;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.4rem;

      svg {
        font-size: 1.2rem !important;
      }

      &:hover {
        color: #fabd72 !important;
        transition: 0.4s;
      }
    }
  }

  &:hover {
    background-color: #fefefe;
    transition: 0.4s;
  }

  &:hover &__logo {
    color: #212121;
    transition: 0.4s;
  }

  &:hover &__menuBox__menuItem {
    color: #212121;
    transition: 0.4s;
  }

  @media screen and (max-width: 1200px) {
    &__logo {
      font-size: 2rem !important;
    }
  }

  @media screen and (max-width: 900px) {
    & {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &__logo {
      font-size: 1.8rem !important;
    }
  }

  @media screen and (max-width: 600px) {
    &__logo {
      flex: none !important;
      font-size: 1.3rem !important;
      margin-top: 1.3 !important;
    }
  }
}

.navbarFix {
  background-color: #fefefe !important;
  position: relative;

  .navbar {
    &__logo {
      color: #212121 !important;
    }

    &__menuBox {
      &__menuItem {
        color: #212121 !important;
        transition: 0.4s;

        &:hover {
          color: #fabd72 !important;
          transition: 0.4s;
        }
      }
    }
  }
}

.disabled {
  cursor: default;
  color: #fefefe !important;

  &:hover {
    color: #212121 !important;
  }
}

.navbar:hover .disabled {
  color: #212121 !important;
}

.drawerBox {
  position: relative;
  padding: 2.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    &__close {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }

    &__title {
      font-weight: bold;
      font-size: 1.5rem !important;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    p {
      text-align: justify;
      line-height: 1.5;
      margin: 0.3rem;
    }

    &__socialMedia {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;

      &__item {
        text-decoration: none !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.4rem;
        cursor: pointer;
        color: #212121 !important;

        svg {
          font-size: 1.5rem !important;
        }

        &:hover {
          color: #fabd72 !important;
          transition: 0.4s;
        }
      }
    }

    &__contactus {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: flex-start;

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.4rem;
        color: #212121 !important;

        svg {
          font-size: 1.5rem !important;
        }

        a {
          text-decoration: none !important;
          color: #212121 !important;
        }
      }
    }
  }

  &__menu {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__menuItem {
      text-decoration: none !important;
      color: #212121 !important;
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        color: #fabd72 !important;
        transition: 0.4s;
      }
    }
  }
}

.divider {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
